/* istanbul ignore file */
import keyBy from 'lodash/keyBy'
import { compile } from 'path-to-regexp'

import { urls } from './art_urls_json'

/***
NOTE: urls should be generated from django repo. Refer to main_urls.py for
      urls info!!!
***/

/***
Creates URLs dictionary in this form (:lang is added to all paths):
{
  <name>: {
    name: <name>,
    path: `:lang${<path>}`,
    url: <path reverse function>,
  },
  ...
}

Example of getting regex path:
URLS.home.path

Example of getting reverse (note: This is used in the util function):
URLS.home.url({lang: 'en'})
***/

export const URLS = keyBy(
  urls.map((path: any) => {
    const resultPath = `${path.app ? '/app' : ''}/:lang${path.path}`
    return {
      name: `${path.name}${path.app ? '_app' : ''}`,
      path: resultPath,
      url: compile(resultPath),
      appUrl: compile(path.appPath || ''),
    }
  }),
  'name'
)
