import type { Language } from '~/i18n.universal'
import { URLS } from './art_urls'

import type { urls } from './art_urls_json'

export type Keys = typeof urls[number]['name']

export default function urlReverse(
  name: Keys,
  kwargs: { lang?: Language; arg1?: string; [key: string]: any } = {},
  query?: string
): string {
  if (!kwargs.lang) {
    kwargs.lang = 'en'
  }
  query = query ? '?' + query : ''

  if (!URLS[name] || !URLS[name].url) {
    return '/en/'
  }

  try {
    return URLS[name].url(kwargs) + query
  } catch (e) {
    // Not clear if this can happen during SSR and Client, so not sure how
    // to import Sentry
    // console.error(e)
    // Raven.captureException(e, {
    //   extra: {
    //     name,
    //     kwargs,
    //     query,
    //   },
    // })
    return '/en/'
  }
}
